// pages/404.js

import React from "react";
import Layout from "../components/layout/Layout";
import QuestionsCarousel from "../components/contact_page/faq/QuestionsCarousel";
import styled from "styled-components";
import { ContactFormContextProvider } from "../components/contact_page/context/ContactFormContext";
import Text from "../components/atoms/Text";
import Title from "../components/atoms/Title";

import ContactFormPytanko from "../components/contact_page/ContactFormPytanko";
import ExpandButtons from "../components/atoms/expand_buttons/ExpandButtons";
const ContactPageContainer = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 300px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 247, 247, 1) 89%
  );

  width: calc(100% - 50px);
  padding-bottom: 140px;
  @media only screen and (max-width: 1399px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1023px) {
    padding-bottom: 280px;
  }
`;
const ExpandButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 470px;
  z-index: 3;
`;
const LeftSide = styled.div`
  width: 50%;
  z-index: 2;
  position: relative;
  padding-left: 40px;
  @media only screen and (min-width: 1460px) and (max-width: 1499px) {
    padding-left: 20px;
  }
  @media only screen and (min-width: 1500px) {
    padding-left: 0;
    margin-left: calc(-394px + 26vw);
  }
  @media only screen and (max-width: 1399px) {
    width: 100%;
    padding-left: 0px;
  }
`;
const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  margin-left: 80px;
  @media only screen and (max-width: 1399px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-left: 0px;
  }
`;
const BackgroundQuestionmark = styled.div`
  content: "?";
  font-size: 750px;
  color: #f7f7f7;
  font-family: Gilroy-ExtraBold;
  position: absolute;
  left: calc(50% + 250px);
  top: 100px;
  transform: translateX(-50%);
  width: 550px;
  z-index: 0;
  height: 1200px;
`;
const Custom404 = () => {
  return (
    <Layout>
      <ContactPageContainer>
        <Title>404 ERROR</Title>
        <Text>Podana strona nie istnieje</Text>
      </ContactPageContainer>
    </Layout>
  );
};

export default Custom404;
