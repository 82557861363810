import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { osName } from "react-device-detect";
import { useActiveQuestion } from "./context/ActiveQuestionContext";
const textAppearing = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}`;

const FaqQuestion = styled.div`
  cursor: ${(props) => (props.data_active ? "inherit" : "pointer")};
  transition: color 1s ease-in-out;
  display: flex;
  position: relative;
  /* top: ${(props) =>
    props.data_active ? "8px" : props.data_isMobile ? "55%" : "50%"}; */
  /* transform: ${(props) => (props.data_active ? "unset" : "translateY(-50%)")}; */
  font-family: CamptonMedium;
  margin-bottom: 9px;
  color: ${(props) => (!props.data_active ? "#2a2a2a" : "#00aeff")};
  flex-direction: column;
  margin-bottom: 0;
    padding: 0;
  &:hover {
    color: #00aeff;
    /* transition: color 0.2s linear; */
  }
  @media only screen and (max-width: 1023px) {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 615px) {
    /* top: ${(props) => (props.data_active ? "12px" : "50%")}; */
    /* transform: ${(props) => (props.data_active ? "unset" : "translateY(-50%)")}; */
  }
  @media only screen and (min-width: 1024px) {
    /* top: ${(props) => (props.data_active ? "20px" : "50%")}; */
    /* transform: ${(props) => (props.data_active ? "unset" : "translateY(-50%)")}; */
  }
`;
const FaqAnswer = styled.div`
  transition: all 0.8s ease-in-out;
  display: inline;
  br {
    display: none;
  }
  text-align: left;
  font-family: CamptonLight;
  color: #2a2a2a;
  animation: ${textAppearing} 1s ease-in-out;
  padding-right: 0;
  position: relative;
  @media only screen and (max-width: 614px) {
    width: calc(100% - 30px);
    top: 10px;
  }

`;
const QuestionContainer = styled.div`

  padding: 20px 50px 20px 20px;
  transition: height 1s ease-in-out;
  text-align: left;
  box-shadow: 0px 6px 25px 1px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  margin-bottom: 16px;
  height: ${(props) => (props.data_active ? "100%" : "60px")};
  max-width: 670px;
  width: 100%;
  font-size: 18px;
  cursor: ${(props) => (props.data_active ? "inherit" : "pointer")};
  user-select: none;
  position: relative;

  background: #fff;
  p {
    transition: all 0.5s ease-in-out;
    display: inline;
  }

  .bold-blue-text {
    display: inline;
    margin-left: 2.5px;
    margin-right: 2.5px;
    font-family: CamptonSemiBold;
    transition: all 0.5s ease-out;
    color: #00aeff;
    animation: ${textAppearing} 0.8s ease-in-out;
  }
  @media only screen and (max-width: 1023px) {
    padding: 12px 30px 15px 15px;
    font-size: 24px;

    height: ${(props) => (props.data_active ? "100%" : "55px")};
  }
  @media only screen and (max-width: 699px) {
    font-size: 22.9px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 14px;
    letter-spacing: -0.5px;
    padding-right: 5px;
    padding-left: 7px;
    padding-bottom: 0;
    padding-top: 0;
    padding-top: ${(props) => (props.data_active ? "8px" : "0")};
    ${(props) =>
      !props.data_active &&
      `
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `}
    border-radius: 7px;
    margin-bottom: 9px;
    /* height: ${(props) => (props.data_active ? "68px" : "35px")}; */

    /* ${(props) =>
      !props.data_active &&
      `
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `} */
  }
  @media only screen and (min-width: 615px) {
  }
  @media only screen and (min-width: 654px) and (max-width: 675px) {
    font-size: 22px;
  }
  @media only screen and (min-width: 632px) and (max-width: 653px) {
    font-size: 21px;
  }
  @media only screen and (min-width: 615px) and (max-width: 631px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1399px) {
    margin-left: -20px;
  }
`;

const IsOpenArrow = styled.div`
  background-image: url(/icons/arrow_up.png);
  transform: ${(props) => (props.data_isOpen ? "rotate(180deg)" : "")};
  opacity: ${(props) => (props.data_isOpen ? "0" : "1")};
  position: absolute;
  top: 26.5px;
  height: 10px;
  user-select: none;
  width: 15px;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
  right: 25px;
  cursor: ${(props) => (props.data_isOpen ? "inherit" : "pointer")};
  @media only screen and (max-width: 1023px) {
    top: 19.5px;
    background-size: contain;
    height: 15px;
    width: 20px;
  }
  @media only screen and (max-width: 614px) {
    top: ${(props) =>
      props.data_isOpen ? "12px" : props.data_isMobile ? "13px" : "11px"};
    right: 7px;
    height: 10px;
    user-select: none;
    width: 15px;
  }
`;
const QuestionTextWrapper = styled.div`
  @media only screen and (max-width: 614px) {
    display: flex;
    padding-right: 0;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: flex-start; */
  }
`;
const Question = ({ question, answer, id }) => {
  const [activeQuestion, setActiveQuestion] = useActiveQuestion();
  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => setIsMobile(osName === "Android" || osName === "iOS"), []);
  useEffect(
    () => (activeQuestion === id ? setActive(true) : setActive(false)),
    [activeQuestion]
  );
  return (
    <QuestionContainer
      onClick={() => setActiveQuestion(id)}
      data_active={active}
    >
      <IsOpenArrow data_isMobile={isMobile} data_isOpen={active} />
      <QuestionTextWrapper>
        <FaqQuestion data_isMobile={isMobile} data_active={active}>
          {question}
        </FaqQuestion>
        {active && <FaqAnswer>{answer}</FaqAnswer>}
      </QuestionTextWrapper>
    </QuestionContainer>
  );
};

export default Question;
