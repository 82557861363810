/*
  <WebrandCarousel 
    className="faq-carousel", 
    containerClass="container", 
    responsive=initialBreakpoints,
    customRightArrow=<CustomRightArrow />,
    customLeftArrow=<CustomLeftArrow />,
    arrows=false,
  >
*/
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import useMediaType from "../hooks/useMediaType";
import Carousel from "react-multi-carousel";

const buttonPulseAnimationBlue = keyframes`

0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px #31A1FF, 0 0 10px 10px #31A1FF, 0 0 0 10px #31A1FF;
}
100% {
  box-shadow: 0 0 0 5px #31A1FF, 0 0 10px 10px #31A1FF, 0 0 0 10px #31A1FF;
  transform: scaleY(1.02);
  opacity: 0;
}
}`;
const buttonPulseAnimationBlueSecond = keyframes`
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 5px #31A1FE, 0 0 10px 10px #31A1FE, 0 0 0 10px #31A1FE;
  }
  100% {
    box-shadow: 0 0 0 5px #31A1FE, 0 0 10px 10px #31A1FE, 0 0 0 10px #31A1FE;
    transform: scaleY(1.02);
    opacity: 0;
  }
  }`;
const StyledCarouselButtonRight = styled.button`
  width: 38px;
  height: 38px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 130px);
  transform: translate(-50% -50%);

  background: #00aeff;
  border: 0;
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    background: blue;
    background: url(/global/Arrow-right-2.svg);
    background-size: 100%;
    height: 13px;
    top: 50%;
    left: calc(50%);
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    top: 0;
    left: 0;
    animation: ${(props) =>
        props.data_clicked === true
          ? buttonPulseAnimationBlue
          : buttonPulseAnimationBlueSecond}
      2s forwards;
  }
  &:hover {
    transform: translate(-50% -50%) scale(1.1);
  }

  @media only screen and (min-width: 615px) {
    left: calc(50% + 195px);
    width: 70px;
    height: 70px;
    top: calc(50% - 20px);

    &:before {
      height: 21px;
      width: 23px;
      top: calc(50% - -2px);
    }
  }
`;
const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  const [leftClicked, setLeftClicked] = useState(false);
  const handleOnClick = () => {
    onClick();
    setLeftClicked(!leftClicked);
  };
  // onMove means if dragging or swiping in progress.
  return (
    <StyledCarouselButtonRight
      data_clicked={leftClicked}
      onClick={() => handleOnClick()}
    ></StyledCarouselButtonRight>
  );
};

const StyledCarouselButtonLeft = styled.button`
  width: 38px;
  height: 38px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 170px);
  transform: translate(-50% -50%);

  background: #00aeff;
  border-radius: 50%;
  border: 0;
  &:before {
    content: "";
    position: absolute;
    background: blue;
    background: url(/global/Arrow-right-2.svg);
    background-size: 100%;
    height: 13px;
    top: calc(50% - 13px);
    left: 50%;
    transform: rotate(180deg) translateX(50%) translateY(-50%);
    width: 15px;
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
  }
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    top: 0;
    left: 0;
    animation: ${(props) =>
        props.data_clicked === true
          ? buttonPulseAnimationBlue
          : buttonPulseAnimationBlueSecond}
      2s forwards;
  }
  &:hover {
    transform: translate(-50% -50%) scale(1.1);
  }
  @media only screen and (min-width: 615px) {
    left: calc(50% - 265px);
    width: 70px;
    height: 70px;
    top: calc(50% - 20px);

    &:before {
      height: 21px;
      width: 23px;
      top: calc(50% - 22px);
    }
  }
`;
const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  const [leftClicked, setLeftClicked] = useState(false);
  const handleOnClick = () => {
    onClick();
    setLeftClicked(!leftClicked);
  };
  // onMove means if dragging or swiping in progress.
  return (
    <StyledCarouselButtonLeft
      data_clicked={leftClicked}
      onClick={() => handleOnClick()}
    ></StyledCarouselButtonLeft>
  );
};
const initialBreakpoints = {
  desktop: {
    breakpoint: {
      max: 5000,
      min: 1280,
    },
    items: 1,
  },
  desktopSmall: {
    breakpoint: {
      max: 1279,
      min: 1024,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
  },
};
const CarouselContainer = styled.div`
  width: 100%;
  counter-reset: faqCounter;
  .react-multi-carousel-item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    margin-top: 50px;
  }
  .react-multi-carousel-track {
    padding-bottom: 30px;
  }

  .react-multi-carousel-dot {
    margin-top: 1px;
    button {
      transition: all 0.5s ease-in-out;
      background: transparent;
      border: none;
      margin-top: 2px;
      &:active {
        background: transparent;
      }
      &:before {
        transition: all 0.2s ease-in-out;
        font-size: 24px;
        font-family: CamptonExtraBold;
        color: #eee;
        counter-increment: faqCounter;
        content: counter(faqCounter);
        position: relative;
        position: relative;
      }
    }
  }
  .react-multi-carousel-dot--active {
    margin-top: 0;
    button {
      &:before {
        color: #00aeff;
        font-size: 26px;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .react-multi-carousel-dot--active {
      button {
        margin-left: 7px;
        margin-right: 7px;
        transform: translateY(1px);
        &:before {
          font-size: 32px;
        }
      }
    }
    .react-multi-carousel-dot {
      button {
        margin-left: 5px;
        margin-right: 5px;
        &:before {
          font-size: 30px;
        }
      }
    }
    .react-multi-carousel-item {
      padding: 0 50px;
      @media only screen and (max-width: 614px) {
        padding: 0 25px;
      }
    }
  }
  @media only screen and (max-width: 614px) {
    .react-multi-carousel-dot--active {
      button {
        margin-left: 3.5px;
        margin-right: 3.5px;
        transform: translateY(1px);
        &:before {
          font-size: 26px;
        }
      }
    }
    .react-multi-carousel-dot {
      button {
        margin-left: 2.5px;
        margin-right: 2.5px;
        margin-bottom: 20px;
        &:before {
          font-size: 23px;
        }
      }
  }
`;
const WebrandCarousel = ({
  children,
  className = "webrand_carousel",
  containerClass = "container",
  responsive = initialBreakpoints,
  customRightArrow = <CustomRightArrow />,
  customLeftArrow = <CustomLeftArrow />,
  arrows = false,
  infinite = false,
  centerMode = false,
  pagination = true,
}) => {
  const mediaType = useMediaType();
  const [media, setMedia] = useState();
  useEffect(() => {
    setMedia(mediaType);
  });
  return (
    <CarouselContainer props_className={className}>
      <Carousel
        customRightArrow={customRightArrow}
        customLeftArrow={customLeftArrow}
        additionalTransfrom={0}
        arrows={arrows}
        autoPlaySpeed={3000}
        ssr={true}
        centerMode={centerMode}
        className={className}
        containerClass={containerClass}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={infinite}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        deviceType={media}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots={pagination}
        sliderClass="slider-cards"
        slidesToSlide={1}
        swipeable
      >
        {children}
      </Carousel>
    </CarouselContainer>
  );
};

export default WebrandCarousel;
