import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { ActiveButtonStateContext } from "./ExpandButtons";

const Container = styled.div.attrs((props) => ({
  data_extrawidth: props.data_extrawidth,
  data_expand_side: props.data_expand_side,
}))`
  &:last-of-type {
    margin-left: 10px;
  }
  width: ${(props) =>
    props.data_expand_side === "left"
      ? (props) => (props.data_extrawidth ? "185px" : "48px")
      : (props) => (props.data_extrawidth ? "185px" : "48px")};
  max-width: ${(props) =>
    props.data_expand_side === "left"
      ? (props) => (props.data_extrawidth ? "185px" : "48px")
      : (props) => (props.data_extrawidth ? "185px" : "48px")};
  height: 48px;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: ${(props) => (props.data_extrawidth ? "center" : "center")};
  align-items: center;
  flex-direction: ${(props) =>
    props.data_expand_side === "left" ? "row-rewerse" : "row"};
  border: 1px solid #afafaf50;
  border-radius: ${(props) => (props.data_expand ? "30px" : "50%")};
  background: ${(props) =>
    props.data_color === "white" ? `#fff` : `rgba(35,157,255,1)`};
  transition: all 0.4s linear
    ${(props) =>
      props.data_extrawidth
        ? ", border-radius 0s linear"
        : ", border-radius 1s linear"};
  svg {
    position: absolute;
    margin-top: 1px;
    left: ${(props) => (props.data_expand_side === "right" ? "11px" : "13px")};
    display: flex;
    fill: rgba(35, 157, 255, 1);
    width: 20px;
    height: 20px;
  }
  @media only screen and (min-width: 615px) {
    width: ${(props) =>
      props.data_expand_side === "left"
        ? (props) => (props.data_extrawidth ? "205px" : "58px")
        : (props) => (props.data_extrawidth ? "205px" : "58px")};
    height: 58px;
    max-width: ${(props) =>
      props.data_expand_side === "left"
        ? (props) => (props.data_extrawidth ? "205px" : "58px")
        : (props) => (props.data_extrawidth ? "205px" : "58px")};
    svg {
      margin-top: 1px;
      left: ${(props) =>
        props.data_expand_side === "right" ? "16px" : "18px"};
      transform: scale(1);
    }
  }
`;

const AppearanceRight = keyframes`
    0%{
        opacity: 0;
        transform: scaleX(0);
    }
    50%{
        opacity: 0;
        transform: scaleX(0.5);
    }
    
    100%{
        transform: scaleX(1);
        opacity: 1;
    }
`;
const DisappearanceDown = keyframes`

    0%{
        opacity: 0;

        transform: translateY(0%) scaleX(0.5);
    }

    20%{
        opacity: 1;
        transform: scaleX(1);
    }
    100%{
        opacity: 0;
        transform: translateY(125%)
    }
`;

const Text = styled.a`
  text-decoration: none;
  animation: ${(props) =>
      props.data_expand_side === "right" ? AppearanceRight : AppearanceRight}
    0.37s ease-in-out;
  animation-fill-mode: forwards;
  font-family: CamptonSemiBold;
  position: absolute;
  color: #0db2ff;
  margin-left: ${(props) =>
    props.data_expand_side === "right" ? "24px" : "10px"};
  font-size: 12px;
  @media (min-width: 615px) {
    font-size: 15px;
  }
`;
const CopyText = styled.p`
  position: absolute;
  font-family: CamptonLight;
  bottom: -15px;
  ${(props) =>
    props.data_expand_side === "right" ? "right: 62px" : "left: 72px"};
  font-size: 9px;
  color: #0db2ff;
  animation: ${DisappearanceDown} 2s ease-out;
  animation-fill-mode: forwards;
  @media only screen and (min-width: 1024px) {
    bottom: 00px;
  }
`;
const ExpandButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;
const ExpandButton = ({
  data_color,
  data_icon_component,
  data_expand_side,
  data_expand_text,
}) => {
  const [expand, setExpand] = useState(false);
  const [icon, setIcon] = useState(false);
  const [isExtraWidth, setIsExtraWidth] = useState(false);
  const [activeExpand, setActiveExpand] = React.useContext(
    ActiveButtonStateContext
  );

  const handleClick = () => {
    if (activeExpand === data_expand_side) {
      setActiveExpand(null);
      setIsExtraWidth(false);
      setExpand(false);
    } else {
      setActiveExpand(data_expand_side);
      setIsExtraWidth(true);
      setExpand(true);
      navigator.clipboard.writeText(data_expand_text);
    }
  };
  useEffect(() => {
    if (activeExpand !== data_expand_side) {
      setIsExtraWidth(false);
      setExpand(false);
    }
    const Componencik = data_icon_component;
    setIcon(<Componencik />);
  }, [activeExpand]);
  return (
    <>
      <ExpandButtonWrapper>
        <Container
          data_extrawidth={isExtraWidth}
          data_expand={expand}
          onClick={handleClick}
          data_expand_side={data_expand_side}
          data_color={data_color}
        >
          {icon}
          {expand && (
            <>
              <Text
                href={`${
                  data_expand_text.includes("+48") ? "tel:" : "mailto:"
                }`}
                data_expand_side={data_expand_side}
              >
                {data_expand_text}
              </Text>
            </>
          )}
        </Container>
      </ExpandButtonWrapper>
      {expand && (
        <>
          <CopyText data_expand_side={data_expand_side}>SKOPIOWANO!</CopyText>
        </>
      )}
    </>
  );
};

export default ExpandButton;
