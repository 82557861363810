import React, { useEffect, useState, useRef } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import ContactFormContainer from "./ContactFormContainer";
import TextInputLiveFeedback from "./TextInputLiveFeedback";
import TextareaAutosizeLiveFeedback from "./TextareaAutosizeLiveFeedback";
import * as Yup from "yup";
import Text from "../atoms/Text";
import Title from "../atoms/Title";
import "yup-phone";
import { useContactFormConfirmation } from "./context/ContactFormContext";
import DragConfirm from "./DragConfirm";
import styled from "styled-components";
import Button from "../atoms/Button";

const StyledText = styled(Text)`
  z-index: 4;
  position: relative;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 16px;
  @media only screen and (max-width: 1023px) {
    font-size: 28px;
  }
`;
const StyledTitle = styled(Title)`
  font-size: 42px;
  z-index: 4;
  position: relative;
  padding-bottom: 40px;
  @media only screen and (max-width: 1023px) {
    font-size: 60px;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 25px;
  font-size: 32px;
  height: 72px;
  border-radius: 50px;
  @media only screen and (min-width: 1024px) {
    font-size: 22px;
    height: 52px;
  }
`;
const ContactFormPytanko = () => {
  const [wholeFormValidated, setWholeFormValidated] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      phoneNumber: "",
      message: ""
    },
    onSubmit: async (values) => {
      alert("xd");
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Musi byc dluzsze, niz 3 znaki")
        .max(30, "Musi byc krotsze, niz 30 znakow")
        .required("*")
        .matches(/^[a-zA-Z0-9 ',./]+$/, "Nie moze zawierac specjalnych znakow"),
      email: Yup.string()
        .email("Nieprawidłowy format adresu e-mail.")
        .required("*"),
      phoneNumber: Yup.string()
        .required("*")
        .phone("PL", true, `Nieprawidłowy format numeru.`),
      message: Yup.string()
        .max(200, "Max 200 znaków")
        .min(20, "Min 20 znaków")
        .required("*")
    })
  });
  const [confirmState, setConfirmState] = useContactFormConfirmation();
  const buttonRef = useRef();

  useEffect(() => {
    setConfirmState(false);
  }, []);
  useEffect(() => {
    if (confirmState) {
      buttonRef.current.disabled = false;
      setWholeFormValidated(true);
    } else {
      buttonRef.current.disabled = true;
      setWholeFormValidated(false);
    }
  }, [confirmState]);

  const checkIsActive = () => {
    confirmState && wholeFormValidated
      ? (buttonRef.current.disabled = false)
      : (buttonRef.current.disabled = true);
    alert(
      `Ty no 30-32 raczej, kolezka sie ladowac idzie dopiero po robocie, a konczy o 22.
No i dopiero wtedy bedzie wiadomo po ile.

Jak ci sie pali to na jarotach mam tez takiego spoko buszka tez, slodki zapaszek, indica(kolezce pomagalem wczoraj to jakies sztuki ma jak bys zobaczyc chcial jak to wyglada).
No i ten to po 30zl na 100%`
    );
  };
  return (
    <ContactFormContainer>
      <StyledText>No ten tego</StyledText>
      <StyledTitle>Przesuń</StyledTitle>
      <FormikProvider id="contact-form" value={formik}>
        <Form className="contact-formik">
          <DragConfirm className="pytanko-dragandconfirm" />

          <div>
            <StyledButton
              onClick={checkIsActive}
              ref={buttonRef}
              data_color="blue"
              type="submit"
            >
              Pokaż
            </StyledButton>
          </div>
        </Form>
      </FormikProvider>
    </ContactFormContainer>
  );
};
export default ContactFormPytanko;
