import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useField } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import ReactTimeout from "react-timeout";

const dissapear = keyframes`
0%{
  opacity: 0;
  transform: scale(0);
}
30%{
  transform: scale(1);
  opacity: 1;
}
100%{
  opacity: 0;
}
`;
const LabelImage = styled.div`
  background: url(${(props) => props.data_src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 12px;
  width: 17px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
`;
const LabelContainer = styled.div`
  background: #00aeff;
  width: 100px;
  height: 43px;
  margin-top: 0px;
  margin-left: -35px;
  border-radius: 50px;
  position: absolute;
  @media only screen and (max-width: 1023px) {
    height: 52px;
  }
  @media only screen and (max-width: 614px) {
    height: 36px;
  }
`;
const InputContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 25px;
  input::placeholder {
    color: #d7d7d7;
    font-family: CamptonBook;
  }
  #placeholder {
    color: #d7d7d7 !important;
    font-family: CamptonBook !important;
  }
  position: relative;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 60px;
    margin-left: 35px;
  }
  @media only screen and (max-width: 614px) {
    margin-left: 25px;
    margin-bottom: 72px;
  }
`;

const InputFeedback = styled.div`
  animation: ${(props) => (props.data_showAnimation ? dissapear : null)} 2s
    ease-in-out;
  position: absolute;
  z-index: 3;
  user-select: none;
  margin-left: 10px;
  transition: all 2000ms ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  background: #ff0000;
  border-radius: 10px;
  color: #fff;
  left: 62%;
  top: 100%;
  white-space: nowrap;
  height: 35px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-family: CamptonBook;
  font-size: 14px;
  display: ${(props) => (props.data_showError ? "flex" : "none")};
  p {
    color: #fff;
    font-size: 14px;
  }
`;
const TextareaContainer = styled.div`
  * {
    resize: none;
  }
`;

const TextareaAutosizePlaceholder = styled.p`
  display: ${(props) => (props.data_visible ? "flex" : "none")};
  position: absolute;
  top: 9.5px;
  left: 20px;
  z-index: 2;
  color: #d7d7d7;
  font-family: CamptonBook;
  font-size: 16px;
  @media only screen and (max-width: 1023px) {
    font-size: 27px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 17px;
    top: 10px;
  }
`;
const TextareaTextLenght = styled.p`
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 4;
  color: #d7d7d7;
  font-family: CamptonBook;
  font-size: 12px;
  @media only screen and (max-width: 1023px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 12px;
  }
`;

const MarkupContainer = styled.div`
  /* background: green; */
  background: url(/icons/check_green.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  color: transparent;
  width: 18px;
  height: 15px;
  z-index: 5;
  right: 16px;
  user-select: none;
  top: 14px;
`;
const StarContainer = styled.div`
  user-select: none;
  color: transparent;
  /* background: yellow; */
  background: url(/icons/close_red.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 14px;
  height: 12px;
  width: 12px;
`;

const Star = () => <StarContainer>*</StarContainer>;
const Markup = () => <MarkupContainer>✓</MarkupContainer>;

const TextareaAutosizeLiveFeedback = ({
  label_image_src,
  label,
  helpText,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [text, setText] = useState("");
  const [showStar, setShowStar] = useState(false);
  const [isMarkupShow, setShowMarkup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isFocusing, setIsFocusing] = useState(false);
  const [isErrorShowAnimation, setIsErrorShowAnimation] = useState(true);
  const [timer, setTimer] = useState("");
  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  const handleFocus = () => {
    setTimeout(() => setDidFocus(true), 800);
    setIsFocusing(true);
  };

  const showMarkup = (error) =>
    error
      ? error !== "*"
        ? setShowMarkup(false)
        : setShowMarkup(false)
      : setShowMarkup(true);

  useEffect(() => {
    if (showError) {
      return;
    }
    if (isFocusing && text.length > 0 && meta.error?.length > 0) {
      if (timer !== "") {
        window.clearInterval(timer);
        setTimer("");
      }
      setShowError(true);
      const thisTimer = setTimeout(() => setShowError(false), 2000);
      setTimer(thisTimer);
    }
  }, [isFocusing]);

  useEffect(() => setText(field.value.toString()), [field.value]);

  useEffect(() => {
    setShowStar(meta.error !== "" && !isFocusing && !!didFocus ? true : false);
  }, [meta.error, isFocusing]);

  useEffect(() => {
    showMarkup(meta.error);
  }, [meta.error]);

  const showFeedback =
    (!!didFocus && field?.value?.trim().length > 0) || meta.touched;

  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [textareaLenghtHolder, setTextareaLenghtHolder] = useState(0);
  useEffect(() => {
    const shouldPlaceholderBeVisible = field.value.length === 0;
    shouldPlaceholderBeVisible
      ? setPlaceholderVisible(true)
      : setPlaceholderVisible(false);
    setTextareaLenghtHolder(field.value.length);
  }, [field]);

  const handleBlur = () => {
    const handleShowError = () => {
      if (showError) return;
      if (timer !== "") {
        window.clearInterval(timer);
        setTimer("");
      }
      setShowError(true);
      const thisTimer = setTimeout(() => setShowError(false), 2000);
      setTimer(thisTimer);
    };
    setIsFocusing(false);
    meta.error ? (meta.error !== "*" ? handleShowError() : null) : null;
  };

  return (
    <InputContainer>
      <div
        className={`form-control ${
          showFeedback ? (meta.error ? "invalid" : "valid") : ""
        }`}
      >
        <div className="flex items-center space-between">
          <label htmlFor={props.id}>
            <LabelContainer>
              <LabelImage data_src={label_image_src} />
            </LabelContainer>
          </label>{" "}
          {showFeedback ? isMarkupShow ? <Markup /> : null : null}
          {showError && (
            <InputFeedback
              data_showError={showError}
              data_showAnimation={isErrorShowAnimation}
              id={`${props.id}-feedback`}
              aria-live="polite"
              className="feedback text-sm"
            >
              {meta.error}
            </InputFeedback>
          )}
          {showStar && !isMarkupShow && <Star />}
        </div>
        <TextareaContainer>
          <TextareaAutosize
            {...props}
            {...field}
            placeholder=""
            aria-describedby={`${props.id}-feedback ${props.id}-help`}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <TextareaAutosizePlaceholder data_visible={placeholderVisible}>
            {props.placeholder}
          </TextareaAutosizePlaceholder>
          <TextareaTextLenght>{textareaLenghtHolder}/200</TextareaTextLenght>
        </TextareaContainer>
        <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
          {helpText}
        </div>
      </div>
    </InputContainer>
  );
};

export default ReactTimeout(TextareaAutosizeLiveFeedback);
