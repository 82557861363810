import React from "react";
import styled from "styled-components";
import Questions from "./Questions";
import WebrandCarousel from "../../atoms/WebrandCarousel";
import Title from "../../atoms/Title";
import Subtitle from "../../atoms/Subtitle";
import Text from "../../atoms/Text";
const TextContainer = styled.div`
  max-width: 768px;
  padding: 0 15px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 22px;
  @media only screen and (max-width: 1023px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 614px) {
    padding: 0 25px;
  }
`;
const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 0;
  font-size: 20px;
  @media only screen and (max-width: 1023px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;
const StyledTitle = styled(Title)`
  margin-bottom: 7px;
  br {
    display: none;
  }
  @media only screen and (max-width: 1399px) {
    br {
      display: inline-flex;
    }
  }
  @media only screen and (max-width: 1023px) {
    font-size: 62px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 36px;
    letter-spacing: -1.8px;
  }
`;
const StyledText = styled(Text)`
  font-size: 18px;
  @media only screen and (max-width: 1023px) {
    font-size: 26px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 614px) {
    font-size: 17px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;
const TextContainerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CarouselContainer = styled.div`
    height: 100%;
  @media only screen and (min-width: 615px) {
    
    height: 740px;
    margin-bottom: 380px;
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: 200px;
    height: 567px;
  }
  @media only screen and (min-width: 1440px){
    margin-bottom: inherit;
    height: 740px;
    .faq-carousel{
      margin-left: -25px;
    }
  }
`;

const QuestionsCarousel = () => {
  const QuestionsData = [
    [
      {
        question: "Dla kogo skierowana jest usługa?",
        // answer: `Jest to nie prawda. Nasza kawa kopie <div class="bold-blue-text">5x bardziej</div> niż zwykła kawa!
        //     GBS jest najmocniejszą kawą na świecie, zawiera 5x więcej kofeiny
        //     niż tradycyjna kawa!`,
        answer: `Dla wszystkich firm, które chcą przenieść swój biznes offline do Internetu korzystając z know-how doświadczonych specjalistów, ale również dla Influencerów posiadających grono odbiorców gotowych kupić produkty z marką Twórcy. Jeżeli chcesz stworzyć swój sklep internetowy oraz własny produkt, nie martwiąc się absolutnie niczym poza promowaniem sklepu i narzucaniem swoich koncepcji - trafiłeś idealnie! `,
        answer_mobile: `Dla wszystkich firm, które chcą przenieść swój biznes offline do Internetu korzystając z know-how doświadczonych specjalistów, ale również dla Influencerów posiadających grono odbiorców gotowych kupić produkty z marką Twórcy. Jeżeli chcesz stworzyć swój sklep internetowy oraz własny produkt, nie martwiąc się absolutnie niczym poza promowaniem sklepu i narzucaniem swoich koncepcji - trafiłeś idealnie! `,
      },
      {
        question: "Czym jest usługa WeBrand „Pod Klucz”?",
        answer: "Jest to usługa przeniesienia absolutnie wszelkich obowiązków dotyczących e-commerce’u z przedsiębiorcy/influencera na specjalistów WeBrand. Wykonamy dla Ciebie w pełni funkcjonalny sklep internetowy, opracujemy grafikę, stworzymy infolinię, obsłużymy zwroty, wystawimy i wyślemy wszystkie faktury do Twoich Klientów. Bez problemu nadamy również zamówienia, zadbamy o opakowania czy skontaktujemy z dostawcami żeby zapewnić bieżące zatowarowanie sklepu. W ogromnym skrócie, zajmiemy się Twoim biznesem od „A do Z” - twoim zadaniem pozostanie wymyślenie wstępnej koncepcji biznesu i przekazaniem nam swojej wizji!",
        answer_mobile: "Jest to usługa przeniesienia absolutnie wszelkich obowiązków dotyczących e-commerce’u z przedsiębiorcy/influencera na specjalistów WeBrand. Wykonamy dla Ciebie w pełni funkcjonalny sklep internetowy, opracujemy grafikę, stworzymy infolinię, obsłużymy zwroty, wystawimy i wyślemy wszystkie faktury do Twoich Klientów. Bez problemu nadamy również zamówienia, zadbamy o opakowania czy skontaktujemy z dostawcami żeby zapewnić bieżące zatowarowanie sklepu. W ogromnym skrócie, zajmiemy się Twoim biznesem od „A do Z” - twoim zadaniem pozostanie wymyślenie wstępnej koncepcji biznesu i przekazaniem nam swojej wizji!",
      },
      {
        question: "Czemu akurat WeBrand?",
        answer: "Jesteśmy praktykami. WeBrand to odpowiedź na to czego wiele lat temu sami szukaliśmy przy okazji tworzenie swoich e-biznesów czyli kompleksowej obsługi dla e-commerce. Od podstaw takich jak wdrożenie platformy sprzedażowej czy obsługa Klienta, po logistykę, magazynowanie produktów, integracje programistyczne, podpisanie umów z przewoźnikami, zadbanie o aspekty prawne, projektowanie opakowalnictwa czy kontakt z dostawcami. Spektrum naszych działań jest niezwykle szerokie, gdyż przez lata praktyki zdążyliśmy poznać ten rynek doskonale - z każdej strony! ",
        answer_mobile: "Jesteśmy praktykami. WeBrand to odpowiedź na to czego wiele lat temu sami szukaliśmy przy okazji tworzenie swoich e-biznesów czyli kompleksowej obsługi dla e-commerce. Od podstaw takich jak wdrożenie platformy sprzedażowej czy obsługa Klienta, po logistykę, magazynowanie produktów, integracje programistyczne, podpisanie umów z przewoźnikami, zadbanie o aspekty prawne, projektowanie opakowalnictwa czy kontakt z dostawcami. Spektrum naszych działań jest niezwykle szerokie, gdyż przez lata praktyki zdążyliśmy poznać ten rynek doskonale - z każdej strony! ",
      },
      {
        question: "Czy wycena jest płatna?",
        answer: "Absolutnie nie. Wycena jest w 100% darmowa - jesteśmy do Twojej dyspozycji! 😉",
        answer_mobile: "Absolutnie nie. Wycena jest w 100% darmowa - jesteśmy do Twojej dyspozycji! 😉",
      },
    ],
    // [
    //   {
    //     question: "Pytanie 1?",
    //     answer: `Odpowiedz <div class="bold-blue-text">niebieska</div> jest super`,
    //     answer_mobile: "Mobile Odpowiedz",
    //   },
    //   {
    //     question: "Pytanie 2",
    //     answer: "Odpowiedz",
    //     answer_mobile: "Mobile Odpowiedz 3",
    //   },
    //   {
    //     question: "Pytanie 3? ",
    //     answer: "Odpowiedz",
    //     answer_mobile: "Mobile Odpowiedz 3",
    //   },
    //   {
    //     question: "Pytanie 4?",
    //     answer: "Odpowiedz",
    //     answer_mobile: "Mobile Odpowiedz 3",
    //   },
    // ],
  ];

  return (
    <CarouselContainer id="pytania">
      <TextContainer>
        <TextContainerContentWrapper>
          <StyledSubtitle>FAQ</StyledSubtitle>
          <StyledTitle>
            Najczęściej <br /> zadawane pytania
          </StyledTitle>
          <StyledText>
            Zanim skontaktujesz się z nami przejrzyj listę najczęsciej
            zadawanych pytań przez naszych klientów. W przypadku braku
            informacji wypełnij i prześlij formularz kontaktowy. Nasz dział
            odpowie najszybciej jak to możliwe!
          </StyledText>
        </TextContainerContentWrapper>
      </TextContainer>
      <WebrandCarousel
        pagination={false}
        className="faq-carousel"
        customRightArrow={null}
        customLeftArrow={null}
        
      >
        {QuestionsData.map((questions) => (
          <Questions questions={questions} />
        ))}
      </WebrandCarousel>
    </CarouselContainer>
  );
};

export default QuestionsCarousel;
