import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ExpandButton from "./ExpandButton";
import TelephoneIcon from "../../icons/TelephoneIcon";
import LetterIcon from "../../icons/LetterIcon";

const ExpandedButtonsWrapper = styled.div`
  display: flex;

  position: absolute;
  width: ${(props) => (props.data_activeExpand ? "400px" : "230px")};
  transition: all 0.4s linear;

  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 6px 25px 1px rgba(0, 0, 0, 0.03);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 10px;
  margin-top: -20px;
  justify-content: ${(props) =>
    props.data_set_expanded ? "space-between" : "center"};
  top: 0;
  left: calc(50% - 5px);
  transform: translateX(-50%);
  @media only screen and (min-width: 615px) and (max-width: 1023px) {
    top: -1023px;
    margin-top: 6px;
    background: #00aeff;
    border-radius: 100px;

    width: ${(props) => (props.data_activeExpand ? "370px" : "200px")};
  }
  @media only screen and (min-width: 1024px) {
    margin: 0;
    left: 0;
    transform: unset;
    margin-top: -2.5px;
    position: relative;
  }
  @media only screen and (max-width: 614px) {
    top: -335px;
    margin-top: 6px;
    background: #00aeff;
    border-radius: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: ${(props) => (props.data_activeExpand ? "300px" : "170px")};
  }
  @media only screen and (min-width: 615px) and (max-width: 614px) {
    top: -963px;
  }
`;
export const ActiveButtonStateContext = React.createContext();

const ExpandButtons = () => {
  const [activeExpand, setActiveExpand] = useState();

  return (
    <ActiveButtonStateContext.Provider value={[activeExpand, setActiveExpand]}>
      <ExpandedButtonsWrapper data_activeExpand={activeExpand}>
        <ExpandButton
          data_setActiveExpand={setActiveExpand}
          data_expand_text={"mikolaj@webrand.pl"}
          data_color="white"
          data_icon_component={LetterIcon}
          data_expand_side="right"
        />
        <ExpandButton
          data_setActiveExpand={setActiveExpand}
          data_expand_text={"+48515991639"}
          data_color="white"
          data_icon_component={TelephoneIcon}
          data_expand_side="left"
        />
      </ExpandedButtonsWrapper>
    </ActiveButtonStateContext.Provider>
  );
};

export default ExpandButtons;
