import styled from "styled-components";
import React, { useState, useEffect } from "react";
import useWindowWidth from "../hooks/useWindowWidth";
// import { useRouter } from "next/router";

const ContactFormContainerWrapper = styled.div`
  .pytanko-dragandconfirm {
    margin-left: 0;
  }
  .contact-formik {
    width: 90vw;
  }
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 11px 33px 13px rgba(0, 0, 0, 0.013);
  padding: 40px 0 60px 0;
  @media only screen and (min-width: 1024px) {
    max-width: 590px;
  }
  border-radius: 20px;
  .contact-formik {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  input,
  textarea {
    &:focus {
      box-shadow: none;
      border-color: #05aeff;
      border-radius: 30px;
      outline: none;
    }
    z-index: 2;
    position: relative;
    width: 390px;
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid #f0f0f0;
    font-family: CamptonBook;
    color: #2a2a2a;
    font-size: 16px;
    padding-right: 34px;
  }
  textarea {
    &:focus {
      box-shadow: none;
      border-color: #05aeff;
      border-radius: 20px;
      outline: none;
    }
    border-radius: 20px;
  }
  #username {
    position: relative;
  }
  #email {
  }
  #phoneNumber {
  }
  #message {
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
    input,
    textarea {
      width: calc(100vw - 100px);
      max-width: 670px;
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 614px) {
    padding: 00px 0 0 0;
    margin-top: -60px;
    border-radius: 0;
    input,
    textarea {
      width: calc(100vw - 100px);
      max-width: 670px;
      /* padding: 6.5px 20px; */
      height: 36.5px;
      font-size: 16px;
    }
  }
`;
const MobileBottomShape = styled.div`
  @media only screen and (max-width: 1023px) {
    background-image: url(/contact_page/mobile_shape_contact_form_bottom.svg);
    background-size: 100vw 110px;
    width: 100%;
    height: 110px;
    margin-top: -30px;
  }
`;
const MobileTopShape = styled.div`
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 110px;
    background-size: 100vw 110px;
    background-image: url(/contact_page/mobile_shape_contact_form_top.svg);
  }
`;

const ContactFormContainer = ({ children }) => {
  const formRef = React.useRef();
  const shapeRef = React.useRef();
  const width = useWindowWidth();
  // const router = useRouter();

  // useEffect(() => {
  //   if (router.asPath.includes("formularz_kontaktowy") && width < 1024) {
  //     shapeRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  //   if(router.asPath.includes("formularz_kontaktowy") && width >= 1024 && width < 1440){
  //     window.scrollTo(0, 700)
  //   }
  //   if(router.asPath.includes("formularz_kontaktowy") && width >= 1440){
  //     window.scrollTo(0, 0)
  //   }
  // });
  return (
    <>
      <MobileTopShape ref={shapeRef} id="formularz" />
      <ContactFormContainerWrapper ref={formRef}>
        {children}
      </ContactFormContainerWrapper>
      <MobileBottomShape />
    </>
  );
};

export default ContactFormContainer;
