import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useField } from "formik";
import ReactTimeout from "react-timeout";
import { osName } from "react-device-detect";

const dissapear = keyframes`
0%{
  opacity: 0;
  transform: scale(0);
}
30%{
  transform: scale(1);
  opacity: 1;
}
100%{
  opacity: 0;
}
`;

const LabelImage = styled.div`
  /* background-color: red; */
  background-image: url(${(props) => props.data_src});
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 24px;
  background-size: fill;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
`;
const LabelContainer = styled.div`
  background: #00aeff;
  /* background: red; */
  width: 100px;
  height: 42px;
  margin-top: 0px;
  margin-left: -35px;
  border-radius: 50px;
  position: absolute;
  @media only screen and (max-width: 1023px) {
    height: 52px;
  }
  @media only screen and (max-width: 614px) {
    height: 36px;
  }
  ${(props) =>
    props.data_isOSX &&
    `
  height: 41px;

  @media only screen and (max-width: 1023px) {
    height: 51px;
  }
  @media only screen and (max-width: 614px) {
    height: 35px;
  }
  `}
`;
const InputContainer = styled.div`
  margin-bottom: 10px;
  height: 45px;
  margin-left: 25px;
  position: relative;
  input::placeholder {
    color: #d7d7d7;
    font-family: CamptonBook;
  }
  @media only screen and (max-width: 1023px) {
    margin-bottom: 20px;
    margin-left: 35px;
  }
  @media only screen and (max-width: 614px) {
    margin-bottom: 10px;
    height: 36px;
    margin-left: 25px;
  }
`;
const InputFeedback = styled.div`
  animation: ${(props) => (props.data_showAnimation ? dissapear : null)} 2s
    ease-in-out;
  position: absolute;
  z-index: 3;
  user-select: none;
  margin-left: 10px;
  transition: all 2000ms ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  background: #ff0000;
  border-radius: 10px;
  color: #fff;
  left: 62%;
  top: 100%;
  white-space: nowrap;
  height: 80%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-family: CamptonBook;
  font-size: 14px;
  display: ${(props) => (props.data_showError ? "flex" : "none")};
  p {
    color: #fff;
    font-size: 14px;
  }
`;
const MarkupContainer = styled.div`
  /* background: green; */
  background: url(/icons/check_green.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  color: transparent;
  width: 18px;
  height: 15px;
  z-index: 5;
  right: 20px;
  user-select: none;
  top: 50%;
  transform: translateY(-50%);
`;
const StarContainer = styled.div`
  user-select: none;
  color: transparent;
  /* background: yellow; */
  background: url(/icons/close_red.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 50%;
  height: 12px;
  width: 12px;
  transform: translateY(-50%);
`;

const Star = () => <StarContainer>*</StarContainer>;
const Markup = () => <MarkupContainer>✓</MarkupContainer>;

const TextInputLiveFeedback = ({
  label_image_src,
  label,
  helpText,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [text, setText] = useState("");
  const [showStar, setShowStar] = useState(false);
  const [isMarkupShow, setShowMarkup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isFocusing, setIsFocusing] = useState(false);
  const [isErrorShowAnimation, setIsErrorShowAnimation] = useState(true);
  const [timer, setTimer] = useState("");
  const inputRef = useRef();

  const showMarkup = (error) =>
    error
      ? error !== "*"
        ? setShowMarkup(false)
        : setShowMarkup(false)
      : setShowMarkup(true);

  useEffect(() => {
    if (showError) {
      return;
    }
    if (isFocusing && text.length > 0 && meta.error?.length > 0) {
      if (timer !== "") {
        window.clearInterval(timer);
        setTimer("");
      }
      setShowError(true);
      const thisTimer = setTimeout(() => setShowError(false), 2000);
      setTimer(thisTimer);
    }
  }, [isFocusing]);

  useEffect(() => setText(field.value.toString()), [field.value]);

  useEffect(() => {
    setShowStar(meta.error !== "" && !isFocusing && !!didFocus ? true : false);
  }, [meta.error, isFocusing]);

  useEffect(() => {
    showMarkup(meta.error);
  }, [meta.error]);

  const handleFocus = () => {
    setTimeout(() => setDidFocus(true), 800);
    setIsFocusing(true);
  };

  const handleBlur = () => {
    const handleShowError = () => {
      if (showError) return;
      if (timer !== "") {
        window.clearInterval(timer);
        setTimer("");
      }
      setShowError(true);
      const thisTimer = setTimeout(() => setShowError(false), 2000);
      setTimer(thisTimer);
    };
    setIsFocusing(false);
    meta.error ? (meta.error !== "*" ? handleShowError() : null) : null;
  };

  const showFeedback =
    (!!didFocus && field?.value?.trim().length > 0) || meta.touched;

  const [isOSX, setIsOSX] = useState(false);
  useEffect(() => setIsOSX(osName === "Mac OS"), []);

  return (
    <InputContainer>
      <div
        className={`form-control ${
          showFeedback ? (meta.error ? "invalid" : "valid") : ""
        }`}
      >
        <div className="flex items-center space-between">
          <label htmlFor={props.id}>
            <LabelContainer data_isOSX={isOSX}>
              <LabelImage data_src={label_image_src} />
            </LabelContainer>
          </label>{" "}
          {showFeedback ? isMarkupShow ? <Markup /> : null : null}
          {showError && (
            <InputFeedback
              data_showError={showError}
              data_showAnimation={isErrorShowAnimation}
              id={`${props.id}-feedback`}
              aria-live="polite"
              className="feedback text-sm"
            >
              {meta.error}
            </InputFeedback>
          )}
          {showStar && !isMarkupShow && <Star />}
        </div>
        <input
          ref={inputRef}
          {...props}
          {...field}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
          {helpText}
        </div>
      </div>
    </InputContainer>
  );
};

export default ReactTimeout(TextInputLiveFeedback);
