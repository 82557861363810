import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Draggable from "react-draggable";
import { useContactFormConfirmation } from "./context/ContactFormContext";
import useWindowWidth from "../hooks/useWindowWidth";
const DraggableConfirmContainer = styled.div`
  width: 385px;
  border: 1px solid #e9e9e9;
  background: #fafafa;
  border-radius: 50px;
  height: 50px;
  /* overflow: hidden; */

  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-left: 30px;
  position: relative;
  margin-bottom: 20px;
  &:before {
    z-index: 2;
    content: "";
    width: ${(props) => props.data_position_x + 50}px;
    height: 50px;
    border-radius: 50px;
    background: #009be6;
    position: absolute;
    left: 0;
  }
  @media only screen and (max-width: 1023px) {
    max-width: 710px;
    width: 100%;
    &:before {
      height: 66px;
      width: ${(props) => props.data_position_x + 66}px;
    }
    margin-left: 10px;

    height: 66px;
  }
  @media only screen and (max-width: 614px) {
    max-width: 710px;
    position: absolute;
    bottom: 120px;
    left: 15px;
    /* transform: translateX(-50%); */
    width: calc(100% - 60px);

    &:before {
      height: 46px;
      width: ${(props) => props.data_position_x + 46}px;
    }
    margin-bottom: 32px;
    height: 46px;
  }
`;
const BoxDrag = styled.div`
  position: absolute;
  cursor: move;
  z-index: 3;
  width: 100%;
  background-color: #00aeff;
  color: black;
  max-width: 50px;
  height: 50px;
  background-image: url(/icons/padlock.png);
  background-size: 17px 19px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  padding: 1em;
  margin: auto;
  user-select: none;
  @media only screen and (max-width: 1023px) {
    background-size: 27px 27px;
    max-width: 66px;
    height: 66px;
  }
  @media only screen and (max-width: 614px) {
    background-size: 14px 17px;
    max-width: 46px;
    height: 46px;
  }
`;
const HelpText = styled.p`
  z-index: 1;
  color: #d8d8d8;
  user-select: none;
  left: 50%;
  font-family: CamptonLight;
  position: relative;
  transform: translateX(-50%);
  font-size: 18px;
  @media only screen and (max-width: 614px) {
    font-size: 14px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }
`;
const DragConfirm = ({ className }) => {
  const width = useWindowWidth();
  const [confirmState, setConfirmState] = useContactFormConfirmation();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const boxDragRef = React.useRef();
  const dragableConfirmContainerRef = React.useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const [media, setMedia] = useState("");
  useEffect(() => {
    setMedia(width > 1023 ? "desktop" : width < 675 ? "mobile" : "tablet");
  }, [width]);
  useEffect(() => {
    setMedia(width > 1023 ? "desktop" : width < 675 ? "mobile" : "tablet");
    setContainerWidth(
      dragableConfirmContainerRef.current.offsetWidth -
        (media === "desktop" || media === "mobile" ? 48 : 68)
    );
  }, [position]);
  const trackPos = (data) => setPosition({ x: data.x, y: 0 });
  const resetPos = () => {
    setPosition({ x: 0, y: 0 });
    setConfirmState(false);
  };
  const setFullPosition = () => {
    setPosition({ x: containerWidth, y: 0 });
    setConfirmState(true);
  };
  const checkIsDone = (data) => {
    data.x >=
    dragableConfirmContainerRef.current.offsetWidth -
      (media === "desktop" || media === "mobile" ? 82 : 98)
      ? setFullPosition()
      : resetPos();
  };
  useEffect(() => {
    setContainerWidth(
      dragableConfirmContainerRef.current.offsetWidth -
        (media === "desktop" || media === "mobile" ? 48 : 68)
    );

    setPosition(
      confirmState
        ? {
            x:
              dragableConfirmContainerRef.current.offsetWidth -
              (media === "tablet" || media === "mobile" ? 48 : 68),
            y: 0,
          }
        : { x: 0, y: 0 }
    );
  }, [width]);
  return (
    <DraggableConfirmContainer
      className={className || ""}
      ref={dragableConfirmContainerRef}
      data_position_x={position.x}
    >
      <HelpText>Przesuń, aby dokończyć werfikację.</HelpText>
      <Draggable
        bounds="parent"
        axis={"x"}
        position={{
          x: position.x,
          y: position.y,
        }}
        onDrag={(e, data) => trackPos(data)}
        onStop={(e, data) => checkIsDone(data)}
      >
        <BoxDrag ref={boxDragRef}>
          {/* <div> x: {position?.x?.toFixed(0)}, y: {position?.y?.toFixed(0)}</div> */}
        </BoxDrag>
      </Draggable>
    </DraggableConfirmContainer>
  );
};

export default DragConfirm;
